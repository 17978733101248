import React, { useEffect, useRef } from "react";
import { debounce } from "lodash";
import { GetServerSideProps, GetServerSidePropsContext, InferGetServerSidePropsType } from "next/types";
import Image from "next/image";
import { NextSeo } from "next-seo";
import Head from "next/head";
import { useRouter } from "next/router";

import SearchForm from "@/containers/SearchForm";
import { httpCmsRequest } from "@/core/Api";
import { useAppSelector } from "@/store/store";
import {
  DomainZone,
  formatDomainZone,
  formatterCollectionId,
  formatterOGImage,
  formatterSingle,
  localization,
  seoParam
} from "@/utils";
import AppStoreImage from "public/landing/app_store-main.png";
import GooglePlayImage from "public/landing/google_play_store.png";
import * as S from "@/styles/index.styled";
import { totalPrice } from "@/utils/currencyFunctions";
import Feedback from "@/components/pages/main/Feedback";
import FAQ from "@/components/pages/main/FAQ";
import BackgroundImage from "public/images/background-main.png";
import BuyImage from "public/images/buy.svg";
import PaymentSecurityImage from "public/images/payment-security.svg";
import RoutesImage from "public/images/routes.svg";
import CallCenterImage from "public/images/call-center.svg";
import MobileAppImage from "public/images/mobile-app-image.png";
import TiktokImage from "public/images/tiktok-main.svg";
import WhatsappImage from "public/images/whatsapp-main.svg";
import InstagramImage from "public/images/instagram-main.svg";
import { ISeo } from "@/types/globalPages";
import Button from "@/components/Buttons";
import Section from "@/components/pages/main/Section";
import Routes from "@/components/pages/main/Routes";
import News from "@/components/pages/main/News";
import { popularTripsMock } from "@/utils/constants";

interface IProps {
  seo: ISeo;
  content: object;
  faq: Faq[];
  routes: Routes[];
  news: any;
}
interface Faq {
  id: number;
  __component: string;
  question: string;
  answer: string;
}
export interface Routes {
  id: number;
  __component: string;
  from: string;
  to: string;
  url: string;
  price: string;
  topThree: boolean;
}

const Home = ({ seo, content, faq, routes, news }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const anchorRef = useRef<HTMLElement>(null);
  const scrollBtnRef = useRef<HTMLButtonElement>(null);
  const currencyStore = useAppSelector((state) => state.currency);
  const router = useRouter();

  const renderPrice = (price: string) => {
    return totalPrice(price, currencyStore.currencyRate);
  };

  const handleSearch = async ({ from, to, date, adult, childs, dateBack }) => {
    const query: Record<string, string> = {
      date,
      adult,
      childs
    };

    if (dateBack) {
      query.dateBack = dateBack;
    }
    await router.push(
      {
        pathname: `/bilety-na-avtobus-${from!.latin_name}--${to!.latin_name}`,
        query
      },
      undefined,
      { scroll: false }
    );
  };

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (anchorRef.current && scrollBtnRef.current) {
        const elementTop = anchorRef.current.getBoundingClientRect().top;

        if (elementTop > 200 || elementTop < -200) {
          scrollBtnRef.current.style.display = "flex";
        } else {
          scrollBtnRef.current.style.display = "none";
        }
      }
    }, 150);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <NextSeo
        title={seo?.metaTitle || ""}
        description={seo?.metaDescription || ""}
        canonical={seo?.canonicalURL || ""}
        openGraph={
          seo?.openGraph
            ? {
                type: seo?.openGraph.type,
                title: seo?.openGraph.title,
                url: seo?.openGraph.url,
                description: seo?.openGraph.description,
                images: formatterOGImage(seo?.openGraph.images)
              }
            : undefined
        }
        additionalMetaTags={[
          {
            property: "keywords",
            content: seo?.keywords || ""
          },
          {
            property: "robots",
            content: seo?.metaRobots || ""
          },
          {
            property: "viewport",
            content: seo?.metaViewport || ""
          }
        ]}
      />
      <Head>
        {!!seo?.structuredData && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(seo?.structuredData) }}
          />
        )}
      </Head>
      <>
        <S.Main>
          <S.TopWrapper isMain>
            <S.ContentWrapper>
              <S.BackgroundImage
                src={BackgroundImage}
                layout="fill"
                objectFit="cover"
                quality={100}
                placeholder="blur"
                alt="фоновая картинка"
              />
              <S.TitleWrapper>
                <S.MainTitle>БИЛЕТЫ НА АВТОБУС ПО БЕЛАРУСИ, РОССИИ, ЕВРОПЕ</S.MainTitle>
              </S.TitleWrapper>
              <SearchForm onSearch={handleSearch} />
            </S.ContentWrapper>
          </S.TopWrapper>
          <Section name="Популярные направления">
            <Routes
              data={popularTripsMock.map((item) => ({
                ...item,
                price: `${renderPrice(String(item.price))} ${currencyStore.currencyAbbreviation}`
              }))}
            />
          </Section>
          <Section white name="Почему мы">
            <S.AboutBlockContainer>
              <S.AboutBlock>
                <S.AboutBlockImage>
                  <Image src={BuyImage} loading="lazy" alt="Покупка онлайн" width={64} height={64} />
                </S.AboutBlockImage>
                <div>
                  <S.AboutBlockTitle>Покупка онлайн</S.AboutBlockTitle>
                  <S.AboutBlockText>Покупайте билет на автобус не выходя из дома</S.AboutBlockText>
                </div>
              </S.AboutBlock>
              <S.AboutBlock>
                <S.AboutBlockImage>
                  <Image src={PaymentSecurityImage} loading="lazy" alt="Коллцентр" width={64} height={64} />
                </S.AboutBlockImage>
                <div>
                  <S.AboutBlockTitle>Безопасная оплата</S.AboutBlockTitle>
                  <S.AboutBlockText>
                    Ваши платежные данные защищены по последним стандартам безопасности
                  </S.AboutBlockText>
                </div>
              </S.AboutBlock>
              <S.AboutBlock>
                <S.AboutBlockImage>
                  <Image src={RoutesImage} loading="lazy" alt="Защита оплаты" width={64} height={64} />
                </S.AboutBlockImage>
                <div>
                  <S.AboutBlockTitle>Более 2000 направлений</S.AboutBlockTitle>
                  <S.AboutBlockText>Большой выбор перевозчиков</S.AboutBlockText>
                </div>
              </S.AboutBlock>
              <S.AboutBlock>
                <S.AboutBlockImage>
                  <Image src={CallCenterImage} loading="lazy" alt="Берегите своё время" width={64} height={64} />
                </S.AboutBlockImage>
                <div>
                  <S.AboutBlockTitle>Поддержка 24/7</S.AboutBlockTitle>
                  <S.AboutBlockText>Получайте консультацию в любое время</S.AboutBlockText>
                </div>
              </S.AboutBlock>
            </S.AboutBlockContainer>
            <Button label="Подробнее о компании" maxWidth={245} isLink path="/about" />
          </Section>
          <Section name="Новости">
            <News data={news} />
          </Section>
          <Section white name="Отзывы" id="feedback">
            <Feedback />
          </Section>
          <Section name="Ответы на популярные вопросы" id="faq">
            <FAQ data={faq} />
          </Section>
          <S.MobileAppSection>
            <S.MobileAppContent>
              <S.MobileAppInfo>
                <S.MobileAppText>
                  Быстро, легко, безопасно – скачайте приложение для покупки билетов в несколько касаний
                </S.MobileAppText>
                <S.MobileAppLinks>
                  <a href="https://apps.apple.com/by/app/probilets/id1575385499" target="_blank">
                    <Image
                      src={AppStoreImage}
                      loading="lazy"
                      placeholder="blur"
                      alt="search bus apple application"
                      width={160}
                      height={40}
                      sizes="100%"
                    />
                  </a>
                  <a
                    // eslint-disable-next-line max-len
                    href="https://play.google.com/store/apps/details?id=com.belexpress"
                    target="_blank">
                    <Image
                      src={GooglePlayImage}
                      loading="lazy"
                      placeholder="blur"
                      alt="search bus android application"
                      width={160}
                      height={40}
                      sizes="100%"
                    />
                  </a>
                </S.MobileAppLinks>
                <S.MobileAppImageContainer2>
                  <Image
                    src={MobileAppImage}
                    alt="probilets mobile application"
                    width={380}
                    height={401}
                    sizes="100%"
                  />
                </S.MobileAppImageContainer2>
                <S.SocialMediaWrapper>
                  <S.SocialMediaLink href="https://chat.whatsapp.com/IRyQiRltYMv4LmnHd2ge3Y">
                    <Image src={WhatsappImage} alt="TikTok" width={40} height={40} />
                  </S.SocialMediaLink>
                  <S.SocialMediaLink href="https://www.instagram.com/probiletscom">
                    <Image src={InstagramImage} alt="TikTok" width={40} height={40} />
                  </S.SocialMediaLink>
                  <S.SocialMediaLink href="https://www.tiktok.com/@probilets.com">
                    <Image src={TiktokImage} alt="TikTok" width={40} height={40} />
                  </S.SocialMediaLink>
                </S.SocialMediaWrapper>
                <S.MobileAppText>Подписывайтесь и следите за нами в соцсетях</S.MobileAppText>
              </S.MobileAppInfo>
              <S.MobileAppImageContainer>
                <Image src={MobileAppImage} alt="probilets mobile application" width={380} height={401} sizes="100%" />
              </S.MobileAppImageContainer>
            </S.MobileAppContent>
          </S.MobileAppSection>
        </S.Main>
      </>
    </>
  );
};

export const getServerSideProps: GetServerSideProps<IProps> = async ({ req }: GetServerSidePropsContext) => {
  const zone: DomainZone | string = formatDomainZone(req.headers.host ?? "");

  try {
    const queryPages = `${seoParam(false)}${localization(zone)}&populate=faq&populate=routes`;
    const { seo, pageContent: content, faq } = await httpCmsRequest("main-page", queryPages, formatterSingle);
    const queryPagesNews = `${seoParam(true)}${localization(zone)}`;
    const specialOffers = await httpCmsRequest("special-offers", queryPagesNews);
    const news = specialOffers
      .map((item) => formatterCollectionId(item, "special-offers"))
      .sort((a, b) => b.id - a.id)
      .splice(0, 3);

    return {
      props: {
        seo,
        content,
        faq,
        news: news
      }
    };
  } catch (e) {
    return { props: { seo: null, content: null, faq: null } };
  }
};

export default Home;
